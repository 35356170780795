<!--
Button to submit contact form at the /contacts route.
-->
<template>
  <input ref    = "form_submit"
         type   = "submit"
         name   = "commit"
         value  = "Submit"
         class  = "btn background-color-aqua-blue py-3 fs-5 text-light mt-2 w-100 fw-bolder contact-form-submit-button"
         @click = "onClick" />
</template>

<script>
  import every from 'lodash/every'

  export default {
    computed: {
      // Internal: return the contact form element.
      form() {
        return document.getElementById('contact_form')
      },

      // Internal: returns true if all inputs in the form are valid.
      hasValidFields() {
        const fields = this.form.querySelectorAll('input, textarea')

        return every(fields, (field) => {
          return field.checkValidity()
        })
      }
    },

    methods: {
      // Button click event handler. If the form fields are valid, disable the button and set the
      // button's message.
      onClick() {
        if (this.hasValidFields) {
          this.$refs.form_submit.disabled = true
          this.$refs.form_submit.value = 'Sending message...'
          this.form.submit()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contact-form-submit-button:disabled {
    background-color: black;
  }
</style>
